import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { setModal, setSubdivision, openModalVideo } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`



function TradeWindsRepsortPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setModal('tradewinds-rsvp'));
  },[])

    return (
      <>       
      <StyledSection>
        <Container>
          <Row>
            <Col>
              <Card bg='trans-white'>
                <Card.Body>
                  <Card.Title><h1 className='blue-color'>About TradeWinds Resort and surrounding areas</h1></Card.Title>
                  <Card.Subtitle><h2><a href='https://www.tradewinds-homosassa.com/' target='_blank' rel='noopener'>TradeWinds Resort in Homosassa, Florida 34448</a></h2></Card.Subtitle>
                  <Card.Text as='div'>                    
                    <p className='indent'>Tradewinds Homosassa Marina Resort is a Mediterranean themed village built around a private canal accessing the Homosassa River. All homes are built above the 100 year flood plain and you can purchase one of the 40 boat slips surrounding the canal as well.</p>
                    {/* <Alert variant='warning' className='text-center'>
                      <h3>Discover TradeWinds Event</h3> August 27<sup>th</sup> from 10am – 2pm, and August 28<sup>th</sup> from 12pm – 4pm<br/>
                      <Alert.Link onClick={() => dispatch(setModal('tradewinds-rsvp'))} target='_blank'>RSVP today to reserve your spot!</Alert.Link>
                    </Alert> */}
                    <Row>
                      <Col xs={12} lg={6} className='py-2'>
                      <Ratio aspectRatio='16x9'>
                        <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEb3dEln_KgMzv_IkVVobYOB&modestbranding=1&autoplay=0&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                      </Col>
                      <Col xs={12} lg={6} className='py-2'>
                        <Stack gap={2} className='align-items-start'>                    
                          <p className='indent'>This fine development offers the quiet nature paradise of Old Homosassa and the enjoyment of waterfront lifestyle to vacationers and owners alike. If you buy for vacation purposes you have the ability to rent when you are not using your home.</p>
                          <p className='indent'>Enjoy the manatees, scalloping &amp; fishing, lazy days on the river, Monkey Island, and the many great riverside restaurants.</p>
                          <p className='indent'>Comprised of 56 home sites, 14 of which are available for purchase and construction. Lots of options for creating your 'best life' on vacation or in retirement. Call RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' title='Click to automatically dial from mobile device'><span itemprop='telephone'>352.794.1426</span></a> for your private tour!</p>
                          <LinkContainer to='/homes-for-sale/homosassa-tradewinds-condos' aria-label='homes for sale in TradeWinds Resort - Homosassa, FL 34448' title='homes for sale in TradeWinds Resort - Homosassa, FL 34448'>
                            <Button variant='blue'>
                              TradeWinds Resort Properties <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>
                          </LinkContainer>
                            <Button variant='blue' href='/assets/documents/TradeWinds-Sales-Info-2022.pdf' aria-label='TradeWinds community price list' title='TradeWinds community price list' target='_blank' rel='noopener'>
                              Click here for community price list <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>       
                        </Stack>    
                      </Col>
                    </Row>                              
                  </Card.Text>              
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='py-2'>       
              <SlideCarousel className='featured-carousel' slideType='community' queryValue='homosassa-tradewinds-condos' count={4} controls={false} cardWrapper={true} bg='trans-black' />              
            </Col>
          </Row>
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default TradeWindsRepsortPage;