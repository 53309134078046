import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import styled from 'styled-components';
import ReviewTile from '../components/ReviewTile';
import Ratio from 'react-bootstrap/Ratio';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { setModal } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function ReviewPage() {
    const dispatch = useDispatch();
    const incrementAmount = 4;
    const [reviewCount, setReviewCount] = useState(incrementAmount);
    const Reviews = [
        {'title':'James Sobus','body':`"From the beginning to the completion of the sale of my property, Irene Snelgrove treated me like. Member of her Family! Her humility, kindness and knowledge were amazing. I am amazed and grateful for all that she did for me. She went well beyond what I had hoped for. I will never hesitate to ask for her services again."`},
        {'title':'Ronald van Manen','body':`"Ruth and her team helped us tremendously with the sale. At the time of closing we were in Europe but Ruth made sure we were able to sign via an online notary worry free. Great service from great people."`},
        {'title':'Barry & Lynn','body':`"We are happy with the deal and we appreciate your due diligence through the process."`},
        {'title':'palmbeachfs','body':`"Though this rating is profiling Ruth Squires I want to mention Shelli Harty with RES Luxury Group, who I feel went far and beyond to help me buy a home. From receiving my text Friday at 8:30 pm to request a sight visit to a home she was not listing the very next day. To suggesting I use a mortgage broker rather than a bank, not to mention to this day answering any question on the new home we bought with her help. Back to Ruth, she is my listing agent on a home. It's only been two weeks but there's no doubt she has plans for getting buyers to see the house."`},
        {'title':'Amy Lupton','body':`"This was the easiest selling experience I've ever had. Ruth was always available and on top of the process. It was stress free and it was a quick sale. I highly recommend Ruth and this company."`},
        {'title':'Matthew Lins','body':`"RES Luxury Group was there to answer any questions, assist with transaction needs, and walked the whole process to the end!"`},
        {'title':'Bob Madeiros','body':`"Ruth and her team did a great job marketing our unique property in a difficult market. She was always there to answer any questions. I would highly recommend Ruth to anyone selling (or buying) in Citrus county!"`},
        {'title':'Greg Ervin','body':`"Ruth and her team helped me buy and sell this Summer (2022). They were fantastic to work with on both sides of the deal. As a seller, they went above and beyond in terms of marketing the home, including multiple opens and other campaigns, as well as a comprehensive digital marketing package. As a buyer, Irene worked with us, making the whole process fun and easy. I would definitely recommend them to anyone looking to buy or sell in the area."`},
        {'title':'Kamille Gavin','body':`"It all started when my husband and I saw a housing magazine in our local UPS while we were running errands. We had been looking for houses for years, but had never found the right one. There was a house we had recently seen online that we were interested in and it just so happened to be listed in the magazine. We knew that it already had a pending offer on it but we thought that it would hurt to call and ask. That's when I met Shelli. Shelli, told me that she would call the realtor and check about the pending offer and call me back. Not even a minute later she called me back and told me that the house wasn't available, but she would find me a house I love! Folks, the rest is history. Shelli contacted me everyday for at least two weeks. Whether it was because she found houses or not, I was always kept in the loop. The day the house we purchased was listed for sale, Shelli called me and said, "Check your email! I think I found the one." She was right. It was everything we had been dreaming for! Shelli went above and beyond, Helping us find a house, Helping us with the paperwork for financing, helping us answer any questions, finding an inspector, a painter, a handy man... and so many other things. She made buying a house easy! (I don't think that's a sentence I thought I would ever say) Long story short, If you're looking for a house, Shelli is your girl.  We are so grateful for her wisdom and friendship, she is truly a person that wants your happiness above anything else. Thank you Shelli!"`},
        {'title':'Greg Ervin','body':`"Irene was a fantastic realtor. Our home buying experience was fun, easy, and very low pressure. We valued her opinion and expertise!"`},
        {'title':'Pam Atwell','body':`"My husband and I enjoyed working with Ruth. She was very professional and stayed on top of things. I would highly recommend her for your real estate needs."`},
        {'title':'Leonard Ferdig','body':`"Irene assisted my wife and I in our purchase of our home in Beverly Hills and literally went above and beyond. Irene is the epitome of “white glove service red carpet treatment”. Two thumbs up!"`},
        {'title':'Vivian Arencibia','body':`"Ruth helped us find our perfect retirement home in 2017 and when we were ready to sell a Tampa house she was our go to. Her expertise and professionalism helped us through the process quickly and effectively. Thanks Ruth!!"`},
        {'title':'Paula Ricketts','body':`"Thank you very much for selling my Mother's home. Brad Zaruba is so talented he quickly took over managing all the details required to sell her home. He relieved all my tension and anxiety. Brad thank you again you are a magician."`},
        {'title':'Sarah Proctor and William Cronshaw','body':`"Me and my husband just purchased our first home and the team at RES Luxury Group were amazing! Our realtor Shelli Harty went above and beyond for us. She took care of all the details and helped us better understand the process!"`},
        {'title':'Stephen Matthews','body':`"After looking far and wide Real Estate Agent, Brad Zaruba, used his techniques and background in construction to help me in finding just the home that fit my needs. Going back to review the home and look for any issues, Brad helped me see potential and price point value in the home I purchased.  He advised me as to the direction on negotiating the final price that was accepted by the sellers.  We had a clean contract that offered a quick settlement and purchase. I would highly recommend Brad Zaruba to anyone wishing to explore purchasing  or selling a property. His knowledge and trustworthy attributes will give you immediate comfort that you selected the correct agent to handle your needs."`},
        {'title':'Jay Krupanich','body':`"Brad was so so wonderful always on top of things and very patient with me and my low high tech abilities. I trusted him 100 percent to do the right thing. I also want to thank shelly for carting me around, you are a lovely person and I enjoyed talking to you."`},
        {'title':'Barbara Cumming','body':`"I want to thank Brad  and the Res Luxury Group for listing and selling our house. I appreciate your attention to detail,  a great online listing with pictures and video, and Brad's follow thru with the buyer selections and closing. I would be happy to refer your group to anyone I know wanting to buy or sell a house."`},
        {'title':'Theresa Isabella','body':`"I recently sold my home through RES Luxury Group. The experience was great! Brad & Ruth were professional, knowledgeable and a pleasure to work with! I highly recommend working with RES Luxury Group for your real estate needs!"`},
        {'title':'Ray and Lisa Costa','body':`"We are pleased to recommend Brad Zaruba with KW. He exhibited an energetic work ethic and professionalism rarely seen today..His attention to detail coupled with a flawless listing was very much appreciated..Thanks Ruth and her team."`},
        {'title':'Barry Buysse','body':`"This review is for the whole team but especially for Shelli Harty. She was knowledgeable, easy going, efficient, hardworking and fun! Since we are living in Indiana, Shelli did 2 video walkthroughs for us. She supplied all the utility info, a list of home inspectors and roofers to choose from and made countless trips to the property to let deliver drivers in and many other things. Thank you Shelli! We would choose her again in a minute. We feel that we will have a great friend when we get moved."`},
        {'title':'Ginger George','body':`"Irene, Brad and Ruth were all wonderful to work with during the entire process! I highly recommend them! Thank you all for a great experience and for the beautiful bundle of goodies!!"`},
        {'title':'Jennifer Marie','body':`"Having Brad help with the purchase of my new home was a pleasure. While being very friendly and down to earth, his honed business skills were obvious. Communication between us was clear, frequent and up to date. I would recommend Brad to a friend in need of a realtor who's calm yet outgoing while maintaining professionalism."`},
        {'title':'Alan Fountain','body':`"Irene went above and beyond my expectations of a realtor, she set the bar high for everyone. So easy to work with, always in a good mood looking out for your best interest, always had houses for us to look at, on time, she made it fun looking for a house!"`},
        {'title':'handfaz','body':`"Shelli was great to work with and she became a friend. She was quick to respond, very patient, and there every step of the way on the purchase of our new home. I would recommend her to anyone that is in the market for a new home."`},
        {'title':'Steve Elsley','body':`"Greatest realty folks I ever dealt with, keep up the good work and contribution to the community."`},
        {'title':'Suzie Rise','body':`"Great communication! Thank you Ruth and Brad and team!"`},
        {'title':'Valerie Register','body':`"Top notch company! Very responsive and helpful with every aspect of our home search and purchase. Shelli is so kind and excellent at her job! She definitely goes above and beyond to make the process smooth and even enjoyable. We are very thankful for Shelli and the RES Luxury Group and will use them exclusively for all our future real estate business."`},
        {'title':'Betsey Rioja','body':`"Irene did a fantastic job and I highly recommend her. She is excellent at what she does and is extremely understanding and willing to listen. And she's very knowledgeable and professional. I'm so glad I found her!"`},
        {'title':'Jacqui and Larry Urban','body':`"Brad Zaruba, Marketing & Operations Manager with RES Luxury Group and Irene Snelgrove with eXp Realty deserve to be recognized. Irene helped us to find a home we love, and together with Brad, they helped us every step of the way to ensure things went smoothly. We sold our former home in the morning, and closed on our new home two hours away later in the day. Brad and Irene remained calm when we were stressed, and were instrumental in providing us with a swift closing. They are professional, responsive, and we highly recommend their services."`},
        {'title':'Angie Taylor','body':`"Irene and her team, especially Brad, are simply amazing! Truly a professional and just NICE organization. Irene is always in constant contact and is a problem solver. Adored this transaction with her!"`},
        {'title':'Justin Goldsmith','body':`"Irene and Brad at RES Luxury Group went above and beyond to make our Real Estate transaction an incredibly easy process! Their work ethic is top notch and communication is responsive. We opted to use a VA loan for a home purchase which required several additional inspections and documents and they were ahead of it every step of the way. They genuinely cared and worked hard to satisfy our particular needs. We will work with them again and give them our highest recommendation to anyone looking for the best Real Estate Team!"`},
        {'title':'Linda Pfister','body':`"Irene did a tremendous job. We are in our mid-seventies and of course overwhelmed with the current crazy market. She was prepared to write an offer at the kitchen counter of the home we eventually purchased. She answered all of our concerns, phone calls and even made good on a promise of two cheese burgers! Best realtor ever...before and after the sale."`},
        {'title':'Christine Jones','body':`"Ruth is a true professional. She over delivered on every level. She got us a full ask offer and presented it to us in person. She maintained personal contact with us throughout the entire sales process. The two brokers we dealt with in the past don't come close to matching Ruth's knowledge and expertise. I highly recommend her."`},
        {'title':'Jill Jenkins Robinson','body':`"We close on our new home September 10<sup>th</sup>.  We are definitely downsizing!  I'm not sure what we are going to do with half of what we brought down with us.  I'm guessing a big sale.  But it will be nice to have less space to keep up with!  We won't have the time because we will be fishing and swimming.  LOL so ready to settle in.  Irene Snelgrove has been a great realtor for us!  Thanks for all you do Irene!"`},
        {'title':'Anita Wolski','body':`"I say yes to having the same experience as all the folks mentioned below. My house is not sold yet but by golly, I feel it will be. It's a very tricky situation but Ruth seems to be on top of it. She works hard to get the job done. God bless her."`},
        {'title':'Sarah Beatty','body':`"We contacted Irene Snelgrove when she was referred to us through our lender, Veterans United. Upon meeting Irene, we immediately knew we were in good hands and she proved that throughout our dealings. Irene truly went above and beyond to ensure our buying experience was as seamless as possible. We would highly recommend Ruth Squires - RES Luxury Group - eXp Realty, especially Irene Snelgrove, for your realty needs."`},
        {'title':'dhowes01','body':`"Ruth squires and Irene Snelgrove are the most fantastic realators! The are very knowledgeable, friendly, and professional agents i have ever worked with. I am thankful for having them on my side purchasing my home"`},
        {'title':'Larry Cavender','body':`"Awesome experience combined with Keller Mortgage"`},
        {'title':'Keith Allen','body':`"Ruth went above and beyond helping us find our home in Pine Ridge. The house wasn't even on the market and she did some real detective work finding it and helping us through the process. In a market with low inventory, she really works hard to help find her clients the right property. We are very grateful for everything she has done for us and highly recommend her for all your real estate needs."`},
        {'title':'Denise Blain','body':`"Ruth was great to work with. She assisted us with the sale of our house and also helped us with finding another home. She was professional and strove to get answers to any questions we had as quickly as possible. I would highly recommend using Ruth if you are looking for a realtor who is both honest and professional."`},
        {'title':'Deb Wiese','body':`"Phenomenal service, honest and there for you anytime you need them. I highly recommend them."`},
        {'title':'James Cady','body':`"Ruth is the most competent realtor we have ever had the pleasure of working with. Her dedication, caring, attention, and follow-up exceed the average by a country mile. We highly recommend her."`},
        {'title':'Mary Stangle','body':`"Ruth Squires was our realtor who sold our house. She was very professional, knowledgeable, and easy to work with. She was always available within a reasonable time. We highly recommend her to anyone who is in the market to buy or sell a house."`},
        {'title':'Donna Coan Ziegenfuss','body':`"They will always come prepared and have the knowledge and experience to help you sale your property. You can tell they love there job and it's important to them to help you achieve your goals."`},
        {'title':'Zillow - Anonymous','body':`"Ruth and Irene are very knowledgeable on the way to move Realestate. Their expertise on marketing and where to market is right on target. Knows the proper way to do photograph and stage a home to its best. Together they handle everything. Such a pleasure to work with them."`},
        {'title':'ML','body':`"Ruth has helped us purchase 2 homes and so she was our logical choice when we chose a realtor to sell our home.  We did interview another top realtor to compare.  Ruth helped us to negotiate for the selling price and buyer's requests all the way thru the process.  She is quietly assertive and always, always on top of things and in communication with everyone involved.  She navigated us thru an unusual issue with the buyers and their mortgage company's change of closing date (twice!) with many calls and consults.  We closed smoothly and without further problems.  We would definitely hire her again!"`},
        {'title':'Susan and Bryan Bobb','body':`"We called Ruth based on one of her listings in Terra Vista - and then the awesome journey began. Within weeks she had helped us buy one and sell our Rainbow Springs home. We have bought and sold many homes in the past, and few professionals like Ruth provide honest feedback and point out things she thought could be problematic for future resell - which is super important! Great job Ruth!!"`},
        {'title':'Rebecca L','body':`"Ruth Squires at RES Luxury Group is exactly what we needed to sell our house, when nobody else could. From our first interactions via telephone to meeting her in person at closing, her professionalism and determination got the job done. She worked with the buyer to get the best terms for us and helped me negotiate the best price imaginable. It was a win-win scenario that only SHE could have made possible. I highly recommend her!"`},
        {'title':'Greg Vernak','body':`"Ruth Squires is AWSOME! Highly recommend her!!!"`},
        {'title':'Tracy Gill','body':`"My Fiancé & I just purchased a new house, we utilized Ruth Squires as our realtor, she is very professional, friendly & help us with our needs, I highly recommend her, as a matter of fact, we can't wait to have her come to our Christmas party."`},
        {'title':'Dennis Hogan','body':`"Mrs. Squires was recommended to me by a close friend who had used her services prior, I'm so glad he did. She did a fantastic job with the sale of my house in a very short period of time. She is professional, very knowledgeable and very easy to talk with. I highly recommend her and wouldn't hesitate to use her services again."`},
        {'title':'cjzdaz4ever','body':`"Ruth Squires is, without a doubt, the best real estate agent we have ever had the pleasure to know or to work with. She is the epitome of professionalism! From our first encounter Ruth listened to what we were looking for in a home, took copious notes, and consistently offered up listings that  came close to fulfilling our needs. It was a true pleasure to work with such a dedicated professional. Ruth was efficient, extremely responsive, and timely throughout every step of the home buying experience. Now that we have closed on the home on Lakecrest Loop we can't wait to work closely with Ruth as we sell our home on Twilight Ln. Ruth is absolutely the best! If you are looking for a pro, no need to look any further that Ruth Squires."`},
        {'title':'Del Zaruba','body':`"Ruth Squires is the epitome of professionalism. From our first encounter she listened to what we were looking for in a home, took copious notes, and proceeded to offer up listings that came close to fulfilling all of our needs. She is absolutely the best! Ruth was dedicated, highly efficient, and extremely responsive throughout every step of the home buying experience. Now that we have closed on the home in Lakeview Villas we cannot wait to work closely with Ruth as we sell our home in Westford Villas. If looking for a real estate agent, no need to look any further than Ruth Squires. She is exceptional and you will not regret your decision to work with her!"`},
        {'title':'nancilroth','body':`"Ruth is compassionate and a problem solver! She made my real estate transaction seamless. She is very knowledgeable regarding the area she covers. Whenever I came across a stumbling block she always found a way through it. I highly recommend her!"`},
        {'title':'Michael Robinson / Dana Hines','body':`"Above and beyond for us... Irene is the best! She went out of her way to make sure we loved our new home, we appreciate everything she has done for us."`},
        {'title':'Louis and Donna Ziegenfuss','body':`"Always there... This is our second deal with Irene one selling and one buying. Each one very professional and knowledgeable. If she is not sure of an answer she will find it. Made each deal go smoothly. Now we will be closing our biggest deal and I have all the confidence in the world in Irene to get it done right."`},
        {'title':'Linda Larsen','body':`"Ruth was a pleasure to work with! She's professional but friendly and will do whatever it takes to get the job done. We had a few issues with our sale and she helped us work through it all while keeping us as happy as possible. She's the best realtor that we have worked with....you can't do better!!"`},
        {'title':'Wendy Sugioka','body':`"RES Luxury Group and especially realtor Ruth Squires have been extremely helpful to my mother and I as we had to sell one home and buy another. Her professionalism and experience is incredible and she has been able to guide us to make very good decisions. She went above and beyond everything we asked and more. And even better she is kind and patient. It has been an enjoyable experience to work with her. God bless Ruth and RES luxury group."`},
        {'title':'Ray and Pepper Turner','body':`"Ms Ruth was the most genuine realtor I've ever met. She cares about her clients and their personalized issues regarding sales. Ms Ruth will be the hardest working realtor and the utmost honest person in the world. You will Not be disappointed. Call Ruth Squires Today."`},
        {'title':'Wayne S. Henry-Green','body':`"Ruth is a terrific Realtor and I enjoyed working with her tremendously. Her knowledge and experience make her a valuable asset, but it is her dedication and tenacity, that set her above the rest. You will be hard pressed to find a Realtor of her caliber. Don't pass up the opportunity to add her to your resources. It will be a decision you will not regret. I look forward to working with Ruth again soon."`},
        {'title':'pepper3162','body':`"Ms Ruth is the most caring individual with your needs first and foremost. She has no hidden agendas and will always communicate with you. She is open, honest and best of all a hard working realtor."`},
        {'title':'Rich Greete','body':`"Ruth is delightful to work with. She gave sound advice on pricing, the current market conditions, and what to reasonably spend money on in preparing our home to sell. Thanks Ruth!"`},
        {'title':'Charles Dudley','body':`"I just want to extend my sincerest thanks and gratitude for your outstanding efforts in bring this closing to fruition. You went 'Above and Beyond'! You were there for me every step of the way. Thank you for your limitless patience, tact, and great chats. I unhesitatingly recommend you to any and all seeking a residential Realtor."`},
        {'title':'Brad Nixon','body':`"We were fortunate to have Ruth Squires recommended to us when we were ready to list our property. I now pass that good fortune on to you by recommending her for your real estate needs. She handled all aspects of our sale in a professional manner.  Her estimate of market value was spot on. Communication was great--kept us in the loop with how things were proceeding both before and after the sale.  Her attention to detail facilitated a smooth and anxiety free transaction.  Before we knew it, it was as simple as 'sign on the dotted line'  Do yourself a favor and contact Ruth whether looking for or listing a property."`},
        {'title':'Alda and Mel Schuldenfrei','body':`"Hiring Ruth Squires as our real estate agent was a great decision on our part. Ruth is a very professional, very dedicated, and very friendly agent. She helped make a somewhat stressful time as easy as possible.  Kudos to Ruth.  We were very pleased with her professionalism."`},
        {'title':'Mr. & Mrs. Tom Zawacki','body':`"Ruth is a hardworking professional 'Luxury Home Agent' who knew exactly how to promote and market our home. Our home sold and we couldn't be happier. If you want to sell or buy a home, no matter the size, contract Ruth, you will not be disappointed."`},
        {'title':'Dave Stock','body':`"Thank you for taking care of all the details on this sale. You made the transaction incredibly easy."`},
        {'title':'Joanne Crowley','body':`"Ruth made selling my home a wonderful experience! She explained everything that would happen in a timeline to make it easier for me to understand the process and what to expect, from appraisals to inspections. She offered great suggestions on how to stage my home for potential home buyers, she made a video of my home and uploaded it onto Zillow where it got a lot of views, plus she took the most amazing photos of my home, inside and out. She planned an Open House, but we didn't need it since my home was under contract after four days of being on the market! Ruth is pleasant and easy to ask questions. She was patient with my many questions, and she was always there when I needed to ask more! I would highly recommend Ruth Squires to buy or sell your next home. She went above and beyond my expectations!"`},
        {'title':'Laurie F.','body':`"Thanks so much for all of your help during this time, you were always professional and I really appreciated the quality of the regular emails that you sent me regarding the status of the property."`},
        {'title':'Melanie and Steve Lee','body':`"Ruth made our out-of-state purchase so streamlined and as stress-free as possible.  She communicated thoroughly at each step of the process.  We looked at many homes as we solidified our choice and she remained patient and insightful.  When our closing became complicated due to the seller, she was immediately and completely on top of the process. This was especially appreciated as we had already returned to Indiana.  We would certainly recommend her and hire her again! Thank you!"`},
        {'title':'Cyndy and Mike Embach','body':`"Thanks to Ruth Squires for her support in selling our home in Pine Ridge.  Ruth was professional and extremely helpful.  Our home was for sale &#34;By Owner&#34; for months and Ruth stepped in and sold it in a matter of weeks!  A big &#34;Thank You&#34; to Ruth"`},
        {'title':'Ms. Arencibia','body':`"Ruth is the best and helped us find our dream home!"`},
        {'title':'Mrs. Lisa Young','body':`"Ruth was professional, warm....listened to our needs...had great knowledge of the area. She went out of her way to make this an easy transaction from New England to purchasing a home in Florida. She had answers for every question we had. She also helped us get the most home for our money...can't say enough good things about our experience with her!"`},
        {'title':'Mr. & Mrs. Tim Caudill','body':`"We just wanted to take a moment to thank you for helping us find our new home. We really appreciate that you took the time to really listen to our wants and needs. Your patience and caring attitude made the entire experience a lot less stressful than it could have been. We really love our new home, and realize that your time and efforts are responsible for us finding it. So we sincerely thank you for everything."`},
        {'title':'Mr. & Mrs. Forrest Young','body':`"We had the pleasure of working with Ruth on the recent sale of our home in Inverness, FL.  She is professional, her presentation was accurate and well done, and her photography skills are outstanding.  You won't be disappointed when listing with Ruth."`},
        {'title':'Mr. & Mrs. Murray Caudill','body':`"We want to express our gratitude for Ruth's help in the selling our old home and the search and finding of our great new home. She never gave up even after all our struggles to find a home that would appraise and she was able to find this great home for us. We know how hard she worked for us and it is greatly appreciated and we will certainly recommend her to everyone we know to be looking for a quality realtor to either buy or sell a home."`},
        {'title':'Mr. Sal Longo','body':`"Ruth is a very professional and willing to help me on my first property in Beverly Hills, she show me at first the area, I never felt rushed, thank you."`},
    ];   
    return (
        <StyledSection>
            <Helmet>
                <script type='text/javascript'>{`
                    var map;
                    var marker = [];

                    var sales2017 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/724443/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$54,000</b><br/>1920 NW 15th Ave<br/>Crystal River, FL 34428</div>", 28.9138807, -82.6160117],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/727958/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$212,434</b><br/>4398 N Grasstree Dr<br/>Pine Ridge, FL 34465</div>", 28.9274607, -82.4738683],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/728460/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$165,000</b><br/>5515 W Hunters Ridge Cir<br/>Lecanto, FL 34461</div>", 28.8649424, -82.5286149],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/716621/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$290,000</b><br/>3977 N Buckhorn Dr<br/>Pine Ridge, FL 34465</div>", 28.9153135, -82.5485657],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/750714/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$65,000</b><br/>9274 N Ashbury Dr<br/>Citrus Springs, FL 34434</div>", 28.9981871, -82.4825621],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/751812/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$125,000</b><br/>35 Linder Cir<br/>Homosassa, FL 34446</div>", 28.750996, -82.5377245],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/751374/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$238,000</b><br/>3702 W Promontory Dr<br/>Pine Ridge, FL 34465</div>", 28.9493723, -82.5011528],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/751473/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$155,000</b><br/>264 N Turkey Pine Loop<br/>Lecanto, FL 34461</div>", 28.8669479, -82.5223579],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/751661/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,054</b><br/>5329 N Amarillo Dr<br/>Pine Ridge, FL 34465</div>", 28.9404027, -82.5127137],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/753561/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$45,000</b><br/>47 S Desoto St<br/>Beverly Hills, FL 34465</div>", 28.9161257, -82.4552712],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/752849/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$347,500</b><br/>4398 N Grasstree Dr<br/>Pine Ridge, FL 34465</div>", 28.927456, -82.4738683],
                    ["<div style='float:left'><img src='/assets/images/listings/mls/753045/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$199,900</b><br/>5667 W Crossmoor Pl<br/>Lecanto, FL 34461</div>", 28.8647784, -82.5309611],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/752000/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$90,000</b><br/>136 W Goldentuft Ct<br/>Beverly Hills, FL 34465</div>", 28.9153537, -82.4390185],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/753746/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$164,000</b><br/>4 Matricaria Ct<br/>Homosassa, FL 34446</div>", 28.7064854, -82.5356744],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/755721/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$256,000</b><br/>7615 N Firwood Cir<br/>Citrus Springs, FL 34433</div>", 28.974505, -82.5115125],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/752657/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$160,000</b><br/>5685 S Kline Ter<br/>Inverness, FL 34452</div>", 28.779535, -82.3392816],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/753700/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$227,500</b><br/>10 Elder Court E<br/>Homosassa, FL 34446</div>", 28.7452779, -82.5158044],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/754557/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$249,000</b><br/>5486 N Pecos Ter<br/>Pine Ridge, FL 34465</div>", 28.9428716, -82.5176466],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/753736/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$160,000</b><br/>6 Kalanchoe Ct<br/>Homosassa, FL 34446</div>", 28.6999543, -82.5175544],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/756289/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$5,000</b><br/>5610 S Marathon Terr<br/>Inverness, FL 34452</div>", 28.780589, -82.3359767],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/752934/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$169,950</b><br/>5380 W Heather Ridge Path<br/>Lecanto, FL 34461</div>", 28.8655115, -82.5258247],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/756729/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$37,919</b><br/>5 S Adams Street<br/>Beverly Hills, FL 34465</div>", 28.92472, -82.4592492],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757074/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$85,000</b><br/>9366 N Citrus Springs Blvd<br/>Citrus Springs, FL 34434</div>", 28.9990385, -82.473551],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757238/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$237,000</b><br/>5409 N Mock Orange Dr<br/>Pine Ridge, FL 34465</div>", 28.9419618, -82.4999042],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757394/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$365,000</b><br/>5756 W Costa Mesa Ln<br/>Pine Ridge, FL 34465</div>", 28.8927225, -82.5323138],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/759181/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$137,000</b><br/>8388 N Pitcairn Way<br/>Citrus Springs, FL 34434</div>", 28.9858342, -82.4436746],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757773/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$46,200</b><br/>6206 W Pinedale Cir<br/>Crystal River, FL 34429</div>", 28.8738118, -82.5401549],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757629/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$104,900</b><br/>47 S Desoto St<br/>Beverly Hills, FL 34465</div>", 28.9161257, -82.4552712],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/756183/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$230,000</b><br/>4395 N Deckwood Dr<br/>Pine Ridge, FL 34465</div>", 28.9267217, -82.5223368],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/752729/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$281,000</b><br/>11786 N Bluff Cove Path<br/>Dunnellon, FL 34434</div>", 29.0351717, -82.4534726],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/751376/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$255,000</b><br/>4583 N Pink Poppy Dr<br/>Pine Ridge, FL 34465</div>", 28.9295929, -82.5182567],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/761131/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$259,900</b><br/>4690 W Horseshoe Dr<br/>Pine Ridge, FL 34465</div>", 28.9386858, -82.5142399],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/760841/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$47,500</b><br/>3995 E Gloria Dr<br/>Hernando, FL 34442</div>", 28.9566261, -82.3700715],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/761092/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$145,000</b><br/>6832 E Waverly St<br/>Inverness, FL 34452</div>", 28.784886, -82.3231502],
                    ["<div style='float:left'><img src='/assets/images/listings/mls/750618/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$64,000</b><br/>110 E Glassboro Ct 12,3-A<br/>Hernando, FL 34442</div>", 28.887376, -82.4348907],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/762370/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$242,900</b><br/>3602 N Chandler Dr<br/>Hernando, FL 34442</div>", 28.9123598, -82.3925573],
                    ["<div style='float:left'><img src='/assets/images/listings/mls/762233/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$53,000</b><br/>2400 Forest Dr 221<br/>Inverness, FL 34453</div>", 28.843995, -82.3640397],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/760885/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,000</b><br/>631 W Doerr Path<br/>Hernando, FL 34442</div>", 28.8974431, -82.445537],
                    ];
                    var sales2018 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757018/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$5,160</b><br/>8255 N Escobar Rd<br/>Citrus Springs, FL 34434</div>", 28.9834761, -82.41365],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/757020/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$6,840</b><br/>8261 N Escobar Rd<br/>Citrus Springs, FL 34434</div>", 28.9833276, -82.4133362],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/766120/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$315,000</b><br/>3979 N Buckhorn Dr<br/>Pine Ridge, FL 34465</div>", 28.9145758, -82.5483967],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/769007/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$20,000</b><br/>3487 N Jeremy Ave<br/>Hernando, FL 34442</div>", 28.914109, -82.4250487],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/768969/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$139,900</b><br/>8751 N Amboy Dr<br/>Citrus Springs, FL 34433</div>", 28.9903002, -82.4895418],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/769008/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$20,000</b><br/>618 E Brandon St<br/>Hernando, FL 34442</div>", 28.915025, -82.4262097],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/764454/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$159,642</b><br/>6206 W Pinedale Cir<br/>Crystal River, FL 34429</div>", 28.8738118, -82.5401549],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/770738/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$237,500</b><br/>535 N Hambletonian Dr<br/>Inverness, FL 34453</div>", 28.8706631, -82.4161355],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/766229/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$215,000</b><br/>4311 N Deckwood Dr<br/>Pine Ridge, FL 34465</div>", 28.9258592, -82.5234994],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/771605/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$70,000</b><br/>550 W Sand Oak Ct<br/>Beverly Hills, FL 34465</div>", 28.9202543, -82.4458332],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/771275/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$96,000</b><br/>650 W Wild Pine Cir<br/>Beverly Hills, FL 34465</div>", 28.9210514, -82.4479495],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/764581/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$178,000</b><br/>2159 W Dolphin Dr<br/>Citrus Springs, FL 34434</div>", 28.9818748, -82.4730856],
                    ["<div style='float:left'><img src='/assets/images/listings/mls/771606/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$87,000</b><br/>3070 N Camomile Pt<br/>Beverly Hills, FL 34465</div>", 28.9083223, -82.4395264],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/762112/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$500,000</b><br/>4286 W Hacienda Dr<br/>Pine Ridge, FL 34465</div>", 28.904403, -82.5067097],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/770752/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$85,000</b><br/>3934 N Huckleberry Pt<br/>Beverly Hills, FL 34465</div>", 28.921085, -82.4458777],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/760932/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$150,000</b><br/>9033 E Tsala Apopka Dr<br/>Inverness, FL 34450</div>", 28.8482658, -82.2852597],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/765974/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$435,000</b><br/>11786 N Bluff Cove Path<br/>Dunnellon, FL 34434</div>", 29.0351717, -82.4534726],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/771807/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$215,500</b><br/>3554 N Chandler Dr<br/>Hernando, FL 34442</div>", 28.9115057, -82.3948969],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/770754/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$285,000</b><br/>5554 N Lamp Post Dr<br/>Pine Ridge, FL 34465</div>", 28.9442768, -82.4725127],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/774104/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$50,000</b><br/>31 S Adams St<br/>Beverly Hills, FL 34465</div>", 28.9226513, -82.4611534],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/771911/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$330,000</b><br/>5 Ryewood Cir<br/>Homosassa, FL 34446</div>", 28.7282898, -82.5087217],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/768733/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$290,000</b><br/>4200 W Pine Ridge Blvd<br/>Pine Ridge, FL 34465</div>", 28.9405661, -82.5061844],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/775202/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$157,500</b><br/>3415 W Webster Pl<br/>Citrus Springs, FL 34433</div>", 28.9931443, -82.4939196],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/774838/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$273,000</b><br/>191 W Olympia St<br/>Hernando, FL 34442</div>", 28.8743341, -82.4399921],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/775350/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$142,000</b><br/>1920 NW 15th Ave<br/>Crystal River, FL 34428</div>", 28.913876, -82.6160117],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/770346/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$267,500</b><br/>4471 W Piute Dr<br/>Pine Ridge, FL 34465</div>", 28.9222206, -82.5113434],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/776197/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$128,500</b><br/>3931 W Forest Dr<br/>Citrus Springs, FL 34433</div>", 28.998712, -82.5026597],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/776476/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$215,000</b><br/>6 Kalanchoe Ct<br/>Homosassa, FL 34446</div>", 28.6999543, -82.5175544],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/774150/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$208,000</b><br/>5693 N Summerfield Pt<br/>Citrus Springs, FL 34434</div>", 28.9462576, -82.4557307],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/775813/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$367,500</b><br/>1278 N Cherry Pop Dr<br/>Hernando, FL 34442</div>", 28.8812459, -82.4189089],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/777519/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$85,000</b><br/>3943 N Spanish Moss Pt<br/>Beverly Hills, FL 34465</div>", 28.9212968, -82.4462777],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/778032/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$71,500</b><br/>640 W Wild Pine Cir<br/>Beverly Hills, FL 34465</div>", 28.920641, -82.447947],
                    ];
                    var sales2019 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/777999/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$307,500</b><br/>2994 W Beamwood Dr<br/>Pine Ridge, FL 34465</div>", 28.9347924, -82.4852906],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/779556/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$260,000</b><br/>4084 W Banjo Ln<br/>Pine Ridge, FL 34465</div>", 28.9080772, -82.5039278],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/779141/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$104,900</b><br/>3667 N Laurelwood Loop<br/>Beverly Hills, FL 34465</div>", 28.9168245, -82.4443518],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/777776/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$155,000</b><br/>3602 E Lazy River Dr<br/>Dunnellon, FL 34434</div>", 29.0004137, -82.3761304],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/779632/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$284,900</b><br/>768 N Man O War Dr<br/>Inverness, FL 34453</div>", 28.8743257, -82.4210327],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/774921/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$211,000</b><br/>5669 W Hunters Ridge Cir<br/>Lecanto, FL 34461</div>", 28.8646228, -82.5319122],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/777229/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$257,000</b><br/>6120 N Nakoma Dr<br/>Pine Ridge, FL 34465</div>", 28.9519404, -82.4693922],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/776891/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,000</b><br/>3995 E Gloria Dr<br/>Hernando, FL 34442</div>", 28.9566261, -82.3700715],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/781862/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$116,000</b><br/>145 W Goldentuft Ct<br/>Beverly Hills, FL 34465</div>", 28.9157251, -82.439272],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/779271/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$80,000</b><br/>195 E Hartford St 3A, Bldg 7<br/>Hernando, FL 34442</div>", 28.886508, -82.4334407],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/779449/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$142,000</b><br/>8365 S Vine Terr<br/>Floral City, FL 34436</div>", 28.7412686, -82.3043254],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/780219/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$349,900</b><br/>4130 N Forest Lake Dr<br/>Hernando, FL 34442</div>", 28.9237828, -82.4052947],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/774293/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$384,900</b><br/>3001 N Sheriff Dr<br/>Pine Ridge, FL 34465</div>", 28.9065473, -82.5333072],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/783160/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$150,000</b><br/>3508 N Pony Dr<br/>Pine Ridge, FL 34465</div>", 28.9144568, -82.5394642],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/781483/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$265,000</b><br/>1550 E Monopoly Loop<br/>Inverness, FL 34453</div>", 28.8768766, -82.4124091],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/782020/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$359,900</b><br/>382 N Quartz Ave<br/>Hernando, FL 34442</div>", 28.8689461, -82.4693834],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/781235/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$185,000</b><br/>8211 N Dothan Dr<br/>Citrus Springs, FL 34433</div>", 28.982466, -82.5106807],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/784128/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$50,000</b><br/>21 N W Ave<br/>Inverness, FL 34453</div>", 28.8592208, -82.395924],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/780847/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$197,000</b><br/>409 W Crestmont Ct<br/>Beverly Hills, FL 34465</div>", 28.9298059, -82.44359],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/783495/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$94,500</b><br/>67 S Jackson St<br/>Beverly Hills, FL 34465</div>", 28.9181167, -82.4617458],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/784205/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$207,000</b><br/>23 E Liberty St<br/>Hernando, FL 34442</div>", 28.879277, -82.4369866],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/783233/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$76,000</b><br/>19331 SW 90th Ln Rd<br/>Dunnellon, FL 34432</div>", 29.0911849, -82.443209],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785339/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$159,900</b><br/>10809 E Gobbler Dr<br/>Floral City, FL 34436</div>", 28.7891195, -82.2562957],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/784922/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$62,000</b><br/>2414 S Hull Terr<br/>Homosassa, FL 34448</div>", 28.8269836, -82.5697783],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/786354/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$25,000</b><br/>8354 S Lake Consuella Dr<br/>Floral City, FL 34436</div>", 28.7414208, -82.2966451],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/784701/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$164,000</b><br/>6544 E Mockingbird Ln<br/>Inverness, FL 34452</div>", 28.7819503, -82.3280261],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/780009/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$103,000</b><br/>28 N Columbus St<br/>Beverly Hills, FL 34465</div>", 28.9222081, -82.4492192],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785621/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$357,500</b><br/>4658 N Candlewood Dr<br/>Pine Ridge, FL 34465</div>", 28.9318812, -82.5012787],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785557/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$267,000</b><br/>964 N Fresno Ave<br/>Hernando, FL 34442</div>", 28.8777151, -82.4381719],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/786260/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$265,000</b><br/>4792 W Custer Dr<br/>Pine Ridge, FL 34465</div>", 28.941636, -82.5160581],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/786444/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$67,000</b><br/>11 S Jackson St<br/>Beverly Hills, FL 34465</div>", 28.922721, -82.4575564],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/782734/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$221,000</b><br/>5668 W Hunters Ridge Cir<br/>Lecanto, FL 34461</div>", 28.864744, -82.531367],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/786385/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$80,000</b><br/>16723 SW 42nd Loop<br/>Ocala, FL 34481</div>", 29.146477, -82.3987089],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785670/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$420,000</b><br/>11914 N Bluff Cove Path<br/>Dunnellon, FL 34434</div>", 29.036684, -82.4517087],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/783875/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$329,000</b><br/>3840 N Calusa Pt<br/>Crystal River, FL 34428</div>", 28.9170321, -82.6266648],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/783071/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$335,000</b><br/>5374 W Yuma Ln<br/>Pine Ridge, FL 34465</div>", 28.945039, -82.5260727],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/781825/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$235,000</b><br/>4399 N Apple Valley Ave<br/>Pine Ridge, FL 34465</div>", 28.9273439, -82.4746933],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785737/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$135,000</b><br/>143 W Casurina Pl<br/>Beverly Hills, FL 34465</div>", 28.9124676, -82.4393652],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785512/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$389,900</b><br/>1691 N Shadowview Path<br/>Hernando, FL 34442</div>", 28.8893173, -82.4506582],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/787084/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$132,000</b><br/>21368 SW Peach Blossom St<br/>Dunnellon, FL 34431</div>", 29.142492, -82.4777397],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/787551/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$290,000</b><br/>303 W Massachusetts St<br/>Hernando, FL 34442</div>", 28.8778147, -82.4419603],
                    ];
                    var sales2020 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/787241/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$160,000</b><br/>47 W Cypress Blvd<br/>Homosassa, FL 34446</div>", 28.7387701, -82.5459243],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/788184/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$580,000</b><br/>3572 Stirrup Dr<br/>Beverly Hills, FL 34465</div>", 28.9142756, -82.5408524],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785947/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$172,777</b><br/>5120 S Mystic Pt<br/>Homosassa, FL 34448</div>", 28.7873586, -82.6341997],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/787547/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$367,500</b><br/>2552 W Axelwood<br/>Beverly Hills, FL 34465</div>", 28.9268758, -82.4791778],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/789048/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$81,000</b><br/>5190 W Cardamon<br/>Homosassa, FL 34446</div>", 28.8596369, -82.5226961],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/789369/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$275,000</b><br/>3096 W Bermuda Dunes<br/>Lecanto, FL 34461</div>", 28.9092288, -82.4879344],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790322/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$4,500</b><br/>9763 N Wayland Ave<br/>Citrus Springs, FL 34434</div>", 29.0061197, -82.4408287],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790009/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$205,500</b><br/>601 N Indianapolis Ave<br/>Hernando, FL 34442</div>", 28.8726026, -82.4437169],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791449/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$108,000</b><br/>221 S Davis St<br/>Beverly Hills, FL 34465</div>", 28.9090496, -82.4628884],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791682/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$329,000</b><br/>1981 Tall Oaks<br/>Beverly Hills, FL 34465</div>", 28.9421394, -82.4716047],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/772322/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$5,500</b><br/>4895 Sandpebble Pt<br/>Crystal River, FL 34428</div>", 28.9336417, -82.5562047],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/772321/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$5,500</b><br/>4929 Sandpebble Pt<br/>Crystal River, FL 34428</div>", 28.9340047, -82.5562077],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/789742/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$390,000</b><br/>2020 W Tall Oaks<br/>Beverly Hills, FL 34465</div>", 28.9435526, -82.4733394],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/789781/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$450,000</b><br/>1894 W Westford Path<br/>Hernando, FL 34442</div>", 28.8885341, -82.4651212],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791869/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$105,000</b><br/>5359 S Marsha Terr<br/>Homosassa, FL 34446</div>", 28.7839987, -82.5472227],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/792010/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$397,000</b><br/>2087 N Annapolis Ave<br/>Hernando, FL 34442</div>", 28.8939657, -82.4218107],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/785344/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$380,000</b><br/>591 E Gilchrist Ct<br/>Hernando, FL 34442</div>", 28.8879192, -82.42689],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797341/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$234,500</b><br/>4519 W Tomahawk<br/>Beverly Hills, FL 34465</div>", 28.9299167, -82.5135335],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/784210/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$52,000</b><br/>1435 Ridgeline<br/>Inverness, FL 34453</div>", 28.8697017, -82.4125007],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/792625/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$375,000</b><br/>1991 W Tall Oaks<br/>Beverly Hills, FL 34465</div>", 28.9426319, -82.4718374],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790746/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$424,000</b><br/>1784 E Gate Dancer Cir<br/>Inverness, FL 34453</div>", 28.8608079, -82.4055432],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793162/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$354,900</b><br/>2599 Mesa Verde<br/>Beverly Hills, FL 34465</div>", 28.9225347, -82.4798037],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/788382/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$265,000</b><br/>10215 W Waterlander<br/>Homosassa, FL 34446</div>", 28.7826035, -82.6214222],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790766/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$240,000</b><br/>5380 W Heather Ridge<br/>Lecanto, FL 34461</div>", 28.8655115, -82.5258247],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793271/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$286,000</b><br/>4752 Ranger St<br/>Beverly Hills, FL 34465</div>", 28.8977897, -82.5152946],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/4161/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$479,000</b><br/>4161 Pansy Lane<br/>Beverly Hills, FL 34465</div>", 28.9314667, -82.5055626],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/1371/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$349,900</b><br/>1371 SW 75th Loop<br/>Dunnellon, FL 34434</div>", 29.1094029, -82.4269746],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793919/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$40,000</b><br/>8370 S Consuella Dr<br/>Floral City, FL 34436</div>", 28.7411992, -82.2966451],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793702/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$175,000</b><br/>6208 E Rush St<br/>Inverness, FL 34453</div>", 28.7920123, -82.3337919],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793874/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$324,000</b><br/>2133 Lakecrest Loop<br/>Hernando, FL 34442</div>", 28.8942598, -82.4604764],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793521/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$625,000</b><br/>499 W Johnny Pesky Ct<br/>Hernando, FL 34442</div>", 28.8919178, -82.4449232],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794540/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$255,000</b><br/>9710 SW 190th Terr<br/>Dunnellon, FL 34434</div>", 29.0826296, -82.4405053],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790385/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$282,500</b><br/>3088 W Bermuda Dunes<br/>Lecanto, FL 34461</div>", 28.9093265, -82.4876595],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794503/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$253,000</b><br/>631 W Doerr Path<br/>Hernando, FL 34442</div>", 28.8974478, -82.445537],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794199/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$295,000</b><br/>10227 Middleburg Lp #161<br/>Homosassa, FL 34442</div>", 28.7917937, -82.6056465],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/792600/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$340,000</b><br/>230 Pine St<br/>Homosassa, FL 34446</div>", 28.7375147, -82.5256286],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793279/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$575,000</b><br/>4818 N Valley<br/>Beverly Hills, FL 34465</div>", 28.9324168, -82.5388869],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794809/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$195,000</b><br/>5385 SW 84th Pl<br/>Ocala, FL 34476</div>", 29.1002162, -82.2112095],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793920/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$45,000</b><br/>8010 SE 1st Ave<br/>Ocala, FL 34480</div>", 29.1058573, -82.1377449],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795267/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$360,000</b><br/>1237 N Man O War<br/>Hernando, FL 34442</div>", 28.8808577, -82.4198947],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795299/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$575,000</b><br/>1516 Bowman Terr<br/>Hernando, FL 34442</div>", 28.8856952, -82.4404365],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791844/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$220,000</b><br/>3069 W Bermuda Dunes<br/>Lecanto, FL 34461</div>", 28.9094935, -82.4870038],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795388/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$287,000</b><br/>4288 N Monadnock<br/>Hernando, FL 34442</div>", 28.9242224, -82.413002],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791691/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$406,000</b><br/>1606 W Westford Path<br/>Hernando, FL 34442</div>", 28.8924985, -82.4632827],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/796054/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$193,000</b><br/>1015 Brookfield Dr<br/>Lecanto, FL 34461</div>", 28.8476224, -82.5002849],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795868/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$315,000</b><br/>7145 SW 182nd<br/>Dunnellon, FL 34432</div>", 29.0972073, -82.4404716],
                    ];
                    var sales2021 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/799173/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$310,000</b><br/>12150 SE County Road 337<br/>Dunnellon, FL 34431</div>", 29.1546527, -82.560562],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/796735/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$280,000</b><br/>582 W Doerr Path<br/>Hernando FL 34442</div>", 28.8958932, -82.4460502],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/791013/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$349,900</b><br/>10510 N Silverlake Pt<br/>Dunnellon, FL 34434</div>", 29.0169427, -82.405699],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797424/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$320,000</b><br/>5530 Elkcam Blvd<br/>Pine Ridge, FL 34465</div>", 28.943688, -82.4878974],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793470/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$650,000</b><br/>4711 S Amstel Dr<br/>Homosassa, FL 34448</div>", 28.7932982, -82.607994],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797622/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$459,900</b><br/>6107 N Elkcam Blvd<br/>Pine Ridge, FL 34465</div>", 28.9519351, -82.4797119],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/796373/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$210,000</b><br/>5359 S Marsha Terr<br/>Homosassa, FL 34446</div>", 28.7839987, -82.547228],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794269/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$249,950</b><br/>9350 SE Co Rd 337<br/>Dunnellon, FL 34432</div>", 29.194548, -82.5787237],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/793115/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$415,000</b><br/>2894 W Beamwood Dr<br/>Pine Ridge, FL 34465</div>", 28.925981, -82.4848757],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/792482/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$585,000</b><br/>12014 W Bayshore Dr<br/>Crystal River, FL 34429</div>", 28.8811297, -82.6369644],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797298/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$200,995</b><br/>1500 N Marlborough Loop<br/>Crystal River, FL 34429</div>", 28.8856034, -82.5349558],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798025/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$454,000</b><br/>4293 W Piute Dr<br/>Pine Ridge, FL 34465</div>", 28.9259382, -82.5089333],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797044/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$140,000</b><br/>257 W Casurina Pl<br/>Beverly Hills, FL 34465</div>", 28.9116261, -82.4411773],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795716/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$749,000</b><br/>6907 W Sentinel Bluff Path<br/>Beverly Hills, FL 34465</div>", 28.9413383, -82.5525001],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798196/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$375,000</b><br/>4070 N Saddletree Dr<br/>Pine Ridge, FL 34465</div>", 28.9221458, -82.516502],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798461/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$125,000</b><br/>206 S Fillmore St<br/>Beverly Hills, FL 34465</div>", 28.9125414, -82.4639462],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/799961/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$150,000</b><br/>10830 N Quarry Dr<br/>Citrus Springs, FL 34434</div>", 29.021228, -82.4560623],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798236/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$350,000</b><br/>2509 W Riley Dr<br/>Citrus Springs, FL 34434</div>", 28.9646221, -82.4782471],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/799681/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$179,000</b><br/>3962 N Tamarisk Pt<br/>Beverly Hills, FL 34465</div>", 28.9216161, -82.4374734],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798232/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,000</b><br/>8280 N Ibsen Dr<br/>Citrus Springs, FL 34433</div>", 28.9835898, -82.4843388],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/799092/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$65,000</b><br/>5260 S Oldfield Ave<br/>Homosassa, FL 34446</div>", 28.7857884, -82.5254442],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795965/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$445,000</b><br/>1826 W Twilight Ln<br/>Hernando, FL 34442</div>", 28.8895671, -82.4669928],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798110/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$130,000</b><br/>3327 N Tamarisk Ave<br/>Beverly Hills, FL 34465</div>", 28.9120952, -82.4374523],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/795888/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$239,900</b><br/>2797 N Hythe Pt<br/>Hernando, FL 34442</div>", 28.903717, -82.3896177],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797877/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$357,000</b><br/>4810 S Amstel Dr 125<br/>Homosassa, FL 34448</div>", 28.7932982, -82.607994],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/800000/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$412,500</b><br/>105 W Massachusetts St<br/>Hernando, FL 34442</div>", 28.8778103, -82.4386587],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/800161/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$175,000</b><br/>88 S J Kellner Blvd<br/>Beverly Hills, FL 34465</div>", 28.9073761, -82.462973],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801291/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$120,000</b><br/>4791 S Amstel Dr<br/>Homosassa, FL 34448</div>", 28.7932982, -82.607994],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/790384/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$530,000</b><br/>1522 N Eagle Ridge Path<br/>Hernando, FL 34442</div>", 28.8843569, -82.4654695],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801314/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$134,000</b><br/>4794 S Polderland Way<br/>Homosassa, FL 34448</div>", 28.791637, -82.6064087],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/797458/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$545,000</b><br/>815 N Hunt Club Dr<br/>Hernando, FL 34442</div>", 28.8762716, -82.4571829],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801294/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$140,000</b><br/>4788 S Polderland Way<br/>Homosassa, FL 34448</div>", 28.791771, -82.6064817],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801844/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$305,900</b><br/>67 W Doerr Path<br/>Hernando, FL 34442</div>", 28.893575, -82.4380337],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802943/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$14,000</b><br/>TBD SW Persimmon Ln<br/>Dunnellon, FL 34431</div>", 29.1491792, -82.4947801],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802058/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$301,500</b><br/>9707 N Choir Terr<br/>Citrus Springs, FL 34433</div>", 29.0040643, -82.5012576],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802893/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$210,500</b><br/>241 W Hollyfern Pl<br/>Beverly Hills, FL 34465</div>", 28.910486, -82.441008],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/803794/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$300,000</b><br/>5667 W Crossmoor Pl<br/>Lecanto, FL 34461</div>", 28.8647737, -82.5309611],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802319/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$210,000</b><br/>257 W Casurina Pl<br/>Beverly Hills, FL 34465</div>", 28.9116261, -82.4411773],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801150/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$497,400</b><br/>1392 N Ridge Meadow Path<br/>Hernando, FL 34442</div>", 28.8833689, -82.4655965],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/803968/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$145,000</b><br/>28 N Columbus St<br/>Beverly Hills, FL 34465</div>", 28.9222081, -82.4492192],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798798/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$415,000</b><br/>95 W Mickey Mantle Path<br/>Hernando, FL 34442</div>", 28.8903615, -82.4386375],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802714/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$315,000</b><br/>1109 S Chateau Pt<br/>Inverness, FL 34450</div>", 28.8468681, -82.3135671],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/805341/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$420,500</b><br/>1981 W Tall Oaks Dr<br/>Pine Ridge, FL 34465</div>", 28.9428799, -82.4729886],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804322/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$635,000</b><br/>4075 N Pony Dr<br/>Pine Ridge, FL 34465</div>", 28.9217593, -82.5335608],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/800221/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$137,100</b><br/>4823 S Amstel Dr<br/>Homosassa, FL 34448</div>", 28.7932982, -82.607994],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804327/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$362,400</b><br/>4224 W Pine Ridge Blvd<br/>Pine Ridge, FL 34465</div>", 28.9405944, -82.5066918],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806208/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$330,000</b><br/>1634 E Tradewind Dr<br/>Hernando, FL 34442</div>", 28.917299, -82.4094147],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806977/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$164,000</b><br/>3070 N Camomile Pt<br/>Beverly Hills, FL 34465</div>", 28.9083223, -82.4395264],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806101/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$48,100</b><br/>1136 N Cherry Pop Dr<br/>Hernando, FL 34442</div>", 28.879623, -82.4189147],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806868/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$137,000</b><br/>3962 N Spanish Moss Pt<br/>Beverly Hills, FL 34465</div>", 28.9215633, -82.4465951],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/805746/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$225,500</b><br/>806 Mayflower Ave<br/>Inverness, FL 34452</div>", 28.8226342, -82.3348729],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806075/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$213,750</b><br/>5465 W Cisco St<br/>Pine Ridge, FL 34465</div>", 28.9417017, -82.5278116],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806213/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$299,900</b><br/>3062 S Franklin Terr<br/>Inverness, FL 34450</div>", 28.8176922, -82.2878856],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/794269/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$499,900</b><br/>9350 SE Co Rd 337<br/>Dunnellon, FL 34431</div>", 29.1935132, -82.5786893],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798377/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$180,000</b><br/>7088 W Green Acres St<br/>Homosassa, FL 34446</div>", 28.7908687, -82.553468],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798076/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$3,125</b><br/>Lot 30 SE 133 Terr<br/>Dunnellon, FL 34431</div>", 29.1735504, -82.546842],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798078/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$3,125</b><br/>Lot 31 SE 133 Terr<br/>Dunnellon, FL 34431</div>", 29.1735504, -82.546842],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/799187/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$160,000</b><br/>6201 E Iona Ln<br/>Inverness, FL 34452</div>", 28.804454, -82.3338342],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/798088/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$136,500</b><br/>9384 N Ashbury Dr<br/>Citrus Springs, FL 34434</div>", 28.999956, -82.4804017],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802943/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$14,000</b><br/>TBD SW Persimmon Ln<br/>Dunnellon, FL 34431</div>", 29.1491792, -82.4956674],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801480/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$195,000</b><br/>2099 W Silver Hill Ln<br/>Lecanto, FL 34461</div>", 28.8941352, -82.4712663],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/803340/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$150,000</b><br/>4 Cypress Run 43A<br/>Homosassa, FL 34446</div>", 28.73871, -82.540324],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/803462/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$332,000</b><br/>11655 E Steamboat Dr<br/>Floral City, FL 34436</div>", 28.7571793, -82.2422465],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804646/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$379,000</b><br/>4864 N Lena Dr<br/>Pine Ridge, FL 34465</div>", 28.9342878, -82.471097],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/807339/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$800,000</b><br/>2376 Co Rd 202<br/>Oxford, FL 34484</div>", 28.9503225, -82.0653109],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804688/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$46,000</b><br/>1427 N Hambletonian Dr<br/>Hernando, FL 34442</div>", 28.883691, -82.4157387],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801985/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$225,000</b><br/>5795 East Arbor St<br/>Inverness, FL 34452</div>", 28.7968324, -82.3406593],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/805489/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$121,000</b><br/>146 W Seymeria Dr<br/>Beverly Hills, FL 34465</div>", 28.9091221, -82.4392725],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/805520/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$155,000</b><br/>152 E Angelfish Ct<br/>Beverly Hills, FL 34465</div>", 28.9529509, -82.4358815],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804078/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$195,000</b><br/>328 S Monroe St<br/>Beverly Hills, FL 34465</div>", 28.9093019, -82.471520],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806968/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$575,000</b><br/>5327 N Mallows Cir<br/>Pine Ridge, FL 34465</div>", 28.9410363, -82.4757298],
                    ];
                    var sales2022 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/807999/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$270,000</b><br/>2180 W Learwood Pl<br/>Pine Ridge, FL 34465</div>", 28.9443405, -82.472874],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/808901/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$245,000</b><br/>8654 N Zaval Ave<br/>Dunnellon, FL 34433</div>", 28.9882088, -82.5404561],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/800414/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$100,000</b><br/>14754 W River Rd<br/>Inglis Fl 34449</div>", 29.025758, -82.684374],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/800431/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,000</b><br/>7272 Turner Camp Rd<br/>Inverness Fl 34453</div>", 28.8785836, -82.3152209],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804406/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$185,000</b><br/>475 W Bonset Ct<br/>Beverly Hills, FL 34465</div>", 28.9199615, -82.4445635],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806112/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$725,000</b><br/>9797 E Baymeadows Dr<br/>Inverness, FL 34450</div>", 28.8235804, -82.2767842],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/807230/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$975,000</b><br/>1856 N Buttonwood Lp<br/>Hernando, FL 34442</div>", 28.8898325, -82.4573449],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/809804/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$257,500</b><br/>185 W Hollyfern Pl<br/>Beverly Hills, FL 34465</div>", 28.9112047, -82.4423304],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/810249/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$390,000</b><br/>160 W Liberty St<br/>Hernando, FL 34442</div>", 28.8788576, -82.4418013],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811916/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$570,000</b><br/>5765 N Oakmont Dr<br/>Pine Ridge, FL 34465</div>", 28.9474514, -82.4662883],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811887/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$310,000</b><br/>1928 W Rutland Dr<br/>Citrus Springs, FL 34434</div>", 29.0114956, -82.471489],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813162/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$405,000</b><br/>7306 Woodhall Ct<br/>Tampa, FL 33634</div>", 28.014899, -82.5632293],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813121/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$499,900</b><br/>5688 N Oakmont Dr<br/>Pine Ridge, FL 34465</div>", 28.9458225, -82.4658292],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814420/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$425,000</b><br/>145 Pine St<br/>>Homosassa, FL 34446</div>", 28.7285216, -82.5271352],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815281/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$290,000</b><br/>143 W Casurina Pl<br/>Beverly Hills, FL 34465</div>", 28.9124307, -82.4393148],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811379/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$430,000</b><br/>4874 S Tromp Loop<br/>Homosassa, FL 34448</div>", 28.7906707, -82.6047706],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814192/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$270,000</b><br/>12562 W Fort Island Trail<br/>Crystal River, FL 34429</div>", 28.8984606, -82.6479557],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/809233/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$135,000</b><br/>825 N Maynard Ave<br/>Lecanto, FL 34461</div>", 28.8746325, -82.5189755],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/809232/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$65,000</b><br/>841 N Maynard Ave<br/>Lecanto, FL 34461</div>", 28.8748016, -82.5189649],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/809520/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$180,000</b><br/>30 N Lincoln Ave<br/>Beverly Hills FL 34465</div>", 28.9244236, -82.451949],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/808780/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$219,000</b><br/>4385 N Bacall Loop<br/>Beverly Hills FL 34465</div>", 28.9269205, -82.452404],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/808733/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$185,000</b><br/>416 W Seymeria Dr<br/>Pine Ridge, FL 34465</div>", 28.9097928, -82.4376773],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/810410/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,000</b><br/>21571 SW Peach Blossom St<br/>Dunnellon FL 34431</div>", 29.1429598, -82.4810815],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811149/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$179,000</b><br/>21615 SW Raintree St<br/>Dunnellon FL 34431</div>", 29.1438606, -82.481843],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813020/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$500,000</b><br/>1815 W Herick Ct<br/>Hernando, FL 34442</div>", 28.8920583, -82.4686964],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813581/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$222,000</b><br/>11498 N Wahoo Trail<br/>Dunnellon FL 34433</div>", 29.0301164, -82.566282],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814462/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$200,000</b><br/>4948 S Galzerano Pt<br/>Homosassa FL 34446</div>", 28.78998, -82.5364775],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814942/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$380,000</b><br/>5319 W Buckskin Dr<br/>Pine Ridge, FL 34465</div>", 28.9290192, -82.5249368],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815236/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$175,000</b><br/>530 W Sand Oak Ct<br/>Beverly Hills, FL 34465</div>", 28.9203731, -82.4477483],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806277/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$300,000</b><br/>5581 W Chive Loop<br/>Homosassa, FL 34448</div>", 28.8515256, -82.5281921],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/807977/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$539,000</b><br/>192 E Keller Ct<br/>Hernando, FL 34442</div>", 28.8804961, -82.4336424],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/803589/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,000</b><br/>4981 SW 171st Ct<br/>Dunnellon, FL 34432</div>", 29.1395186, -82.4092],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804677/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$975,000</b><br/>14801 W River Rd<br/>Inglis, FL 34449</div>", 29.0305673, -82.6841124],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813497/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$200,000</b><br/>5780 N Boot Pt<br/>Hernando, FL 34442</div>", 28.947459935161277, -82.36513480050043],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/796297/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$324,140</b><br/>15 Fingertree Ct<br/>Homosassa, FL 34446</div>", 28.7443716, -82.5064437],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/805742/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$270,000</b><br/>8430 E Oak Ridge St<br/>Floral City, FL 34436</div>", 28.7339447, -82.296433],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/808830/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$380,000</b><br/>90 Canterbury Rd<br/>Inglis, FL 34449</div>", 29.0338614, -82.6954583],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811473/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$665,000</b><br/>4757 S Amstel Dr<br/>Homosassa, FL 34448</div>", 28.7923094, -82.6075812],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/810792/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$290,000</b><br/>8623 N Dora Way<br/>Citrus Springs, FL 34434</div>", 28.9890867, -82.4251753],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811913/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$255,000</b><br/>977 Pritchard Island Rd<br/>Inverness, FL 34450</div>", 28.8459009, -82.321115],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811216/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$570,000</b><br/>5417 N Rosedale Cir<br/>Pine Ridge, FL 34465</div>", 28.943872, -82.4919951],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFRG5057899/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$402,000</b><br/>830 Cr 457<br/>Lake Panasoffkee, FL 33538</div>", 28.7912937, -82.1211209],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814676/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$540,000</b><br/>5102 N Perry Dr<br/>Pine Ridge, FL 34465</div>", 28.9373779, -82.5040699],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFRW7845557/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$360,000</b><br/>15007 Dusky Warbler Rd<br/>Brooksville, FL 34614</div>", 28.6408161, -82.504546],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM637282/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$238,000</b><br/>6339 NW 15th Ave<br/>Ocala, FL 34475</div>", 29.2534219, -82.1548866],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811362/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$540,000</b><br/>1716 E Tradewind Dr<br/>Hernando, FL 34442</div>", 28.9174191, -82.4078356],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813862/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$356,000</b><br/>8236 N Sarazen Dr<br/>Citrus Springs, FL 34434</div>", 28.9833654, -82.4673313],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/808868/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$255,000</b><br/>96 W Goldcoast Pl<br/>Citrus Springs, FL 34434</div>", 28.9701448, -82.4388068],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/809375/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$162,400</b><br/>3706 N Honeylocust Dr<br/>Beverly Hills, FL 34465</div>", 28.9176935, -82.4396111],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/810869/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$141,500</b><br/>23 N Harrison St<br/>Beverly Hills, FL 34465</div>", 28.925204, -82.4541709],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/801342/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$7,900</b><br/>2307 Tyler St<br/>Inverness, FL 34453</div>", 28.8553217, -82.3619437],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811956/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$30,000</b><br/>3216 E Marcia St<br/>Inverness, FL 34453</div>", 28.8800467, -82.3823687],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/811974/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$230,000</b><br/>779 W Cockatiel Loop<br/>Hernando, FL 34442</div>", 28.906674, -82.4503619],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/812610/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$140,000</b><br/>4608 W Costello Ln<br/>Homosassa, FL 34446</div>", 28.792988, -82.5146331],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/812966/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$90,000</b><br/>2562 N Treasure Pt<br/>Hernando, FL 34442</div>", 28.9002514, -82.368366],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813829/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$170,000</b><br/>6975 N Gold Leaf Pt<br/>Dunnellon, FL 34433</div>", 28.963732, -82.5676341],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815469/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$425,000</b><br/> 13931 SE 85th Pl<br/>Dunnellon, FL 34431</div>", 29.2076983, -82.5417822],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815354/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$370,000</b><br/>2523 W Mesa Verde Dr<br/>Pine Ridge, FL 34465</div>", 28.9259571, -82.478924],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFRG5055520/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$145,000</b><br/>10842 Isaacs Ct<br/>Leesburg, FL 34788</div>", 28.8556197,-81.778861],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804858/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$364,000</b><br/>10197 W Waterlander Loop #137<br/>Homosassa, FL 34446</div>", 28.7826035, -82.6214222],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/804862/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$409,000</b><br/>4863 S Evertsen Terrace Unit #138<br/>Homosassa, FL 34446</div>", 28.7826035, -82.6214222],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/812385/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,000</b><br/>4981 SW 171st Ct<br/>Dunnellon, FL 34432</div>", 29.139514, -82.4047153],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/818806/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$275,000</b><br/>7820 N Turf Way<br/>Citrus Springs, FL 34434</div>", 28.977193, -82.470551],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815754/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$370,000</b><br/>900 N Lafayette Way<br/>Inverness, FL 34453</div>", 28.8761043, -82.4041435],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/816807/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$240,000</b><br/>5274 S James Terrace<br/>Homosassa, FL 34448</div>", 28.7849873, -82.5949618],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/818633/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$245,000</b><br/>6395 NW 64th Ave<br/>Ocala, FL 34482</div>", 29.2537547, -82.2246047],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/818633/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$245,000</b><br/>6395 NW 64th Ave<br/>Ocala, FL 34482</div>", 29.2537547, -82.2246047],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM650330/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$270,000</b><br/>20459 SW 57th St<br/>Dunnellon, FL 34431</div>", 29.1308888, -82.4602765],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/819170/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$10,600</b><br/>TBD 152 Ave<br/>Williston, FL 32696</div>", 29.4083738, -82.520646],
                    ];
                    var sales2023 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/806542/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$385,000</b><br/>4865 S Tromp Loop<br/>Homosassa, FL 34448</div>", 28.7906707, -82.6047706],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM650330/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$270,000</b><br/>20459 SW 57th St<br/>Dunnellon, FL 34431</div>", 29.1308888, -82.4602765],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815722/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$50,000</b><br/>1136 N Cherry Pop Dr<br/>Hernando, FL 34442</div>", 28.879623, -82.416726],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/816070/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$110,000</b><br/>9781 E Baymeadows Dr<br/>Inverness, FL 34450</div>", 28.8237779, -82.2781209],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/816810/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$319,000</b><br/>3535 W Pine Ridge Blvd<br/>Pine Ridge, FL 34465</div>", 28.9393507, -82.4957227],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/818645/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$609,400</b><br/>151 Athenia Dr<br/>Homosassa, FL 34446</div>", 28.7509297, -82.5290455],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/819938/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$365,000</b><br/>776 W Horizon Ln<br/>Citrus Springs, FL 34434</div>", 28.957747, -82.4500568],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/819841/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$405,000</b><br/>4608 W Costello Ln<br/>Homosassa, FL 34446</div>", 28.7929833, -82.5150193],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/820147/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$358,000</b><br/>9041 E Gospel Island Rd<br/>Inverness, FL 34450</div>", 28.8628708, -82.2855038],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825161/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$419,000</b><br/>4868 Tromp Loop Lot #133<br/>Homosassa, FL 34448</div>", 28.7906707, -82.6047706],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/820608/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$449,000</b><br/>628 W Doerr Path<br/>Hernando, FL 34442</div>", 28.8971359, -82.4464946],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/815551/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$540,000</b><br/>4602 W Casper Ln<br/>Pine Ridge, FL 34465</div>", 28.9309813, -82.5141553],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/821481/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$470,000</b><br/>6924 W Redmond Ct<br/>Dunnellon, FL 34433</div>", 28.9833728, -82.5530935],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822317/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$410,000</b><br/>841 W Skyview Crossing Dr<br/>Hernando, FL 34442</div>", 28.8980399, -82.4513195],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822829/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$436,000</b><br/>11126 N Iron Dr<br/>Citrus Springs, FL 34434</div>", 29.0250262, -82.4707639],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/821751/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$210,000</b><br/>333 S Monroe St<br/>Beverly Hills, FL 34465</div>", 28.9087973, -82.4717794],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822542/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$650,000</b><br/>5327 N Mallow Circle<br/>Pine Ridge, FL 34465</div>", 28.9410363, -82.476116],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/823942/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$1,075,000</b><br/>1416 N Ridge Meadow Path<br/>Hernando, FL 34442</div>", 28.8834163, -82.4687785],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822541/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$160,000</b><br/>2431 S Hull Terrace<br/>Homosassa, FL 34448</div>", 28.82678, -82.5696939],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822920/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$350,000</b><br/>4399 N Apple Valley Ave<br/>Pine Ridge, FL 34465</div>", 28.927344, -82.4750818],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/802365/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$50,000</b><br/>1615 W Ivorywood Dr<br/>Pine Ridge, FL 34465</div>", 28.931436, -82.4642219],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/816308/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$46,500</b><br/>5474 N Bedstrow Blvd<br/>Pine Ridge, FL 34465</div>", 28.942976, -82.4660949],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/823604/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$480,000</b><br/>1344 West Sphere Place<br/>Pine Ridge, FL 34465</div>", 28.9457039, -82.4619498],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825161/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$395,000</b><br/>4856 S Tromp Loop #131<br/>Homosassa, FL 34448</div>", 28.7906707, -82.6047706],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/827485/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$439,900</b><br/>10244 W Waterlander Loop #155<br/>Homosassa, FL 34448</div>", 28.7906707, -82.6047706],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824375/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$279,000</b><br/>1550 W Lorraine Dr<br/>Citrus Springs, FL 34434</div>", 28.9878129, -82.4632746],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/826570/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,500</b><br/>6308 W Lexington Dr<br/>Crystal River, FL 34429</div>", 28.8881561, -82.5445648],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/827019/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,000</b><br/>4 Asters Ct<br/>Homosassa, FL 34446</div>", 28.7233962, -82.5307343],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/827857/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$273,500</b><br/>828 Cedar Ave<br/>Inverness, FL 34452</div>", 28.8213196, -82.3290595],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824425/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$225,000</b><br/>261 E Hartford Street #5B<br/>Hernando, FL 34442</div>", 28.8863848, -82.4329048],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM648757/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$540,000</b><br/>8209 SW 196th Ct Rd<br/>Dunnellon, FL 34432</div>", 29.1029186, -82.4474046],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/821403/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$330,000</b><br/>1189 S Fieldview Loop<br/>Lecanto, FL 34461</div>", 28.8454745, -82.500375],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/813984/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$365,000</b><br/>9689 E White Egret Path<br/>Inverness, FL 34450</div>", 28.86398, -82.2760059],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824442/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$1,200,000</b><br/>5071 Cedar Ridge Dr<br/>Brooksville, FL 34601</div>", 28.5046258, -82.4004748],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825829/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$12,000</b><br/>Lot 18 SW 107 Pl<br/>Dunnellon, FL 34432</div>", 29.0675525, -82.4537841],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFRT3482742/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$435,000</b><br/>12 Dianthus Ct<br/>Homosassa, FL 34446</div>", 28.7086304, -82.5322562],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/828736/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$179,900</b><br/>115 E Hartford St Unit #4A<br/>Hernando, FL 34442</div>", 28.8867265, -82.4351543],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/820716/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$245,000</b><br/>473 N Zebrawood Point<br/>Lecanto, FL 34461</div>", 28.8696873, -82.5257447],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM649164/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$260,495</b><br/>510 Oak St<br/>Inverness, FL 34452</div>", 28.8210922, -82.3268436],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/820957/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$189,000</b><br/>59 S Tyler St<br/>Beverly Hills, FL 34465</div>", 28.9177458, -82.4601537],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/821602/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$215,000</b><br/>8964 N Creek Way<br/>Citrus Springs, FL 34434</div>", 28.9941257, -82.4509174],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/818666/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$378,000</b><br/>5688 S Garcia Rd<br/>Homosassa, FL 34448</div>", 28.7786048, -82.6282694],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822977/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$452,500</b><br/>10570 N Big Bass Trail<br/>Dunnellon, FL 34434</div>", 29.0169838, -82.4258155],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/822186/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$225,000</b><br/>799 W Toucan Loop<br/>Hernando, FL 34442</div>", 28.9057414, -82.4495561],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFRU8198015/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$500,000</b><br/>6005 N Nakoma Dr<br/>Pine Ridge, FL 34465</div>", 28.950721, -82.4628409],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824386/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$170,000</b><br/>3695 N Laurelwood Loop<br/>Beverly Hills, FL 34465</div>", 28.9159929, -82.4448776],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824912/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$54,500</b><br/>1993 W La Bonte Circle<br/>Pine Ridge, FL 34465</div>", 28.948383, -82.4701019],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/FSBO/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$48,000</b><br/>6103 N Nakoma Dr<br/>Pine Ridge, FL 34465</div>", 28.952237, -82.4641859],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/826217/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$520,000</b><br/>5260 E Tenison St<br/>Inverness, FL 34452</div>", 28.7774044, -82.3496317],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/744336/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$67,000</b><br/>TBD McDonald Road<br/>Bonifay, FL 32425</div>", 30.9887762, -85.7610401],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/827133/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$439,900</b><br/>6278 W Cavalry Lane<br/>Pine Ridge, FL 34465</div>", 28.9214759, -82.5441826],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/814844/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$24,995</b><br/>8217 N Verdino Terrace<br/>Crystal River, FL 34428</div>", 28.981462, -82.5820868],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/826568/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$335,000</b><br/>8234 SW 203rd Ct<br/>Dunnellon, FL 34431</div>", 29.1023926, -82.4616454],
                    ];
                    var sales2024 = [
                        ["<div style='float:left'><img src='/assets/images/listings/mls/821326/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$425,000</b><br/>133 E Boston St<br/>Hernando, FL 34442</div>", 28.8967403,-82.4352873],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825133/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$255,000</b><br/>325 E Glassboro Ct<br/>Hernando, FL 34442</div>", 28.8879597,-82.431621],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825492/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$549,000</b><br/>962 N Hambletonian Dr<br/>Inverness, FL 34453</div>", 28.8771553,-82.4171215],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/829379/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$250,000</b><br/>10950 SW 150th St<br/>Dunnellon, FL 34432</div>", 29.0036671,-82.3071156],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/829684/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$300,000</b><br/>6449 S Georgian Rd<br/>Homosassa, FL 34446</div>", 28.7681771,-82.4970721],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830109/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$460,000</b><br/>5675 N Mock Orange Dr<br/>Pine Ridge, FL 34465</div>", 28.9459777,-82.5017627],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830487/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$365,000</b><br/>19905 SW 96th Pl<br/>Dunnellon, FL 34432</div>", 29.0850607,-82.4535723],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830544/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$680,000</b><br/>39513 Hillrise Ln<br/>Lady Lake, FL 32159</div>", 28.9252398,-81.8657367],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830605/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$135,000</b><br/>6672 W Minuteman St<br/>Homosassa, FL 34448</div>", 28.8447487,-82.5475093],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830869/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$329,900</b><br/>12450 S Iris Pt<br/>Floral City, FL 34436</div>", 28.6821403,-82.3054229],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/831964/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$640,000</b><br/>1403 N Skyhawk Pt<br/>Hernando, FL 34442</div>", 28.8837454,-82.4645494],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/832084/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$530,000</b><br/>4070 N Saddletree Dr<br/>Pine Ridge, FL 34465</div>", 28.9221617,-82.5168896],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/832372/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$300,000</b><br/>5740 N Highland Park Dr<br/>Hernando, FL 34442</div>", 28.947251,-82.4168926],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/824554/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$350,000</b><br/>1478 E Monopoly Loop<br/>Inverness, FL 34453</div>", 28.8796292,-82.4153482],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/825984/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$45,000</b><br/>8657 N Catskill Pt<br/>Dunnellon, FL 34433</div>", 28.9880627,-82.5655773],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/MFROM670418/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$227,000</b><br/>4054 Carnation Ct<br/>Dunnellon, FL 34431</div>", 29.1389155,-82.4778687],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/828073/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$190,000</b><br/>4564 W Bonanza Dr<br/>Pine Ridge, FL 34465</div>", 28.9188518,-82.5283442],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/830877/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$27,000</b><br/>6095 N Speckled Perch Terrace<br/>Hernando, FL 34442</div>", 28.9519575,-82.3701665],
                        ["<div style='float:left'><img src='/assets/images/listings/mls/829950/1.jpg?size=296x222'></div><div style='float:right; padding: 10px;'><b>$480,840</b><br/>1818 N Gibson Pt<br/>Hernando, FL 34442</div>", 28.8901891,-82.4503675],
                    ];
                    var salesAll = sales2017.concat(sales2018, sales2019, sales2020, sales2021, sales2022, sales2023, sales2024);
                    function initMap() {
                        var myLatlng = new google.maps.LatLng(28.9325846, -82.5073917);

                        var mapOptions = {
                            zoom: 15,
                            center: myLatlng,
                            gestureHandling: 'cooperative',
                            styles: [{ 'featureType': 'administrative.land_parcel', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'landscape.man_made', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }, { 'lightness': 20 }] }, { 'featureType': 'road.highway', 'elementType': 'geometry', 'stylers': [{ 'hue': '#f49935' }] }, { 'featureType': 'road.highway', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'road.arterial', 'elementType': 'geometry', 'stylers': [{ 'hue': '#fad959' }] }, { 'featureType': 'road.arterial', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road.local', 'elementType': 'geometry', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'road.local', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'transit', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'water', 'elementType': 'all', 'stylers': [{ 'hue': '#a1cdfc' }, { 'saturation': 30 }, { 'lightness': 49 }] }]
                        };

                        var mapElement = document.getElementById('map');
                        map = new google.maps.Map(mapElement, mapOptions);
                        const mapTitleDiv = document.createElement('div');
                        mapTitleDiv.innerHTML = "<h3 style='margin-bottom:0px;'><select name='map_selection' id='map_selection' onchange='loadPins()' value='sales2024'><option value='sales2024' selected>2024 Home Sales Map</option><option value='sales2023'>2023 Home Sales Map</option><option value='sales2022'>2022 Home Sales Map</option><option value='sales2021'>2021 Home Sales Map</option><option value='sales2020'>2020 Home Sales Map</option><option value='sales2019'>2019 Home Sales Map</option><option value='sales2018'>2018 Home Sales Map</option><option value='sales2017'>2017 Home Sales Map</option><option value='salesAll'>Home Sales History Map</option></select></h3><center style='color:#f5821f;margin:auto;'>click pin for image & details</center>";
                        map.controls[google.maps.ControlPosition.TOP_CENTER].push(mapTitleDiv);

                        loadPins();
                    }
                    function loadPins() {
                        if (marker.length > 0)
                        {
                            for (let i = 0; i < marker.length; i++) {
                                marker[i].setMap(null);
                            }
                        }
                        marker = [];
                        var activeInfoWindow;
                        var bounds = new google.maps.LatLngBounds();
                        var locations = document.getElementById('map_selection') ? window[document.getElementById('map_selection').value] : sales2024;
                        for (var i = 0; i < locations.length; i++) {

                            marker[i] = new google.maps.Marker({
                                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                                map: map,
                                title: 'click for image & details'
                            });
                            bounds.extend(marker[i].position);
                            marker[i].infowindow = new google.maps.InfoWindow({
                                content: locations[i][0]
                            });
                            marker[i].addListener('click', function () {
                                activeInfoWindow && activeInfoWindow.close();
                                this.infowindow.open(map, this);
                                activeInfoWindow = this.infowindow;
                            });
                            //marker[i].addListener('mouseover', function () {
                            //    this.infowindow.open(map, this);
                            //});
                            //marker[i].addListener('mouseout', function () {
                            //    this.infowindow.close();
                            //});
                            map.fitBounds(bounds, { top: 30, right: 10, left: 50 });
                        }
                    }
                `}</script>
                <script async type='text/javascript' src='https://maps.googleapis.com/maps/api/js?key=AIzaSyCdSnhAo8YI0UJyoqfLFDPyyejtVaBdK-E&callback=initMap'></script>
            </Helmet>            
            <Container>
                <Row>
                    <Col>
                        <Card bg='trans-white'>
                            <Card.Body>
                                <Row>
                                    <Col className='col-12 order-lg-2'>                
                                        {Reviews.map((review, idx) => (
                                            <ReviewTile key={idx} title={review.title} body={review.body} />
                                        )).filter((review, idx) => { return idx<reviewCount})}
                                        <div className='py-4 clearfix'>                         
                                            <Button className='float-end mx-1' size='lg' variant='orange' onClick={() => {reviewCount<Reviews.length ? setReviewCount(reviewCount+incrementAmount) : setReviewCount(incrementAmount) }} 
                                            children={reviewCount>=Reviews.length ? <>Hide more <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Read more <i role='presentation' className='bi bi-caret-down-fill'></i></>} />
                                            <Button className='float-end mx-1' size='lg' variant='orange' onClick={() => dispatch(setModal('feedback'))}>
                                             Leave feedback <i role='presentation' className='bi bi-caret-right-fill'></i>
                                            </Button> 
                                        </div> 
                                    </Col>
                                    <Col className='col-12 order-lg-1'>
                                        <Ratio aspectRatio='16x9'>
                                            <div id='map' className='w-100 border border-dark'></div>
                                        </Ratio>
                                    </Col>                                    
                                </Row>                                                              
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>       
        </StyledSection>
    )
}

export default ReviewPage;